<template>
    <main>
        <div class="style3d-protocol-container">
            <slot />
        </div>
    </main>
</template>

<style lang="less">
    .text-heavy {
        font-weight: 600;
    }
    .text-italic {
        font-style: italic;
    }
    .style3d-protocol-container {
        padding: .convert(50px) [ @vw];
        color: #262626;
        font-family: 'HarmonyOS Sans SC';
        font-size: @font-size-md;
        font-style: normal;
        font-weight: normal;
        .pages-agreement-title {
            text-align: center;
            font-size: 22px;
            font-weight: 600;
        }
        .pages-agreement-subtitle {
            text-align: center;
            font-size: 18px;
        }
        .pages-agreement-content {
            display: flex;
            flex-direction: column;
            gap: 20px;
            &-head {
                font-size: 24px;
                font-weight: 500;
                font-style: italic;
                margin: 20px 0;
            }
            &-title {
                font-weight: 600;
                font-size: 18px;
            }

            &-text {
                display: flex;
                flex-direction: column;
                gap: 24px;
                ul {
                    padding-inline-start: 40px;
                    list-style: disc;
                    list-style-type: disc;
                    li {
                        list-style: inherit;
                    }
                }

                table {
                    width: 80%;
                    border: 1px solid #000000;
                    text-align: left;
                    border-collapse: collapse;
                    & thead {
                        font-weight: 600;
                    }
                    & td {
                        border: 1px solid #000000;
                        padding: 5px 4px;
                    }

                    @media (max-width: @screen-mobile-width) {
                        width: 100%;
                    }
                }

                &-heavy {
                    font-weight: 600;
                }
            }
        }
    }
</style>
